<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-beach-access"/>
          <h5>Übersicht über die Abwesenheiten eines Mitarbeiters</h5>
          <div class="card-header-actions col-6 text-right">
            <CRow>
              <CCol>
                <MitarbeiterAuswahl
                  v-bind:validate="false"
                  formLabelCLass="d-none"
                  v-model="mitarbeiter"
                />
              </CCol>
              <CCol>
                <CButton color="success" size="sm" v-on:click="newEntry"><CIcon name="cil-library-add"/> Neue Abwesenheit / Krankheit</CButton>
              </CCol>
            </CRow>
          </div>
        </CCardHeader>

        <CCardBody>
          <div v-if="mitarbeiter">

            <CDataTable
              :items="computedItems"
              :fields="fields"
              hover
              :items-per-page-select="{label: 'Einträge pro Seite'}"
              :items-per-page="25"
              column-filter
              sorter
              pagination
            >
              <template #no-items-view><NoData/></template>

              <template #datum="{item}">
                <td v-if="item.datum">
                  {{item.datum|moment('YYYY-MM-DD')}}
                </td>
                <td v-else>-</td>
              </template>

              <template #actionbar="{item}">
                <td nowrap="" width="1%">
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    v-c-tooltip="{content: 'Editieren', placement:'left'}"
                    v-on:click="editEntry(item.abwesenheitid)"
                  >
                    <CIcon name="cil-highlighter"/>
                  </CButton>
                  <CButton
                    color="danger"
                    variant="outline"
                    size="sm"
                    class="ml-2"
                    v-c-tooltip="{content: 'Löschen', placement:'left'}"
                    v-on:click="deleteEntry(item.abwesenheitid)"
                  >
                    <CIcon name="cil-trash"/>
                  </CButton>
                </td>
              </template>
            </CDataTable>

          </div>
          <div v-else>
            <NoData text="Bitte wählen Sie zunächst einen Mitarbeiter aus."/>
          </div>
        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import NoData from '@/components/NoData'
import MitarbeiterAuswahl from '@/components/MitarbeiterAuswahl'
import Vue from 'vue'

export default {
  name: 'AbwesenheitIndex',
  components: {
    NoData,
    MitarbeiterAuswahl
  },
  props: {
    uuid: [Number, String]
  },
  computed: {
    computedItems () {
      if (this.abwesenheiten) {
        if (this.abwesenheiten.length > 0) {
          return this.abwesenheiten.map(abw => {
            const result = {
              abwesenheitid: abw.abwesenheitid,
              name: abw.name,
              art: abw.art,
              datum: abw.datum,
              dauer: abw.dauer
            }

            return result
          })
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  data () {
    return {
      mitarbeiter: null,
      abwesenheiten: null,
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'art',
          label: 'Art'
        },
        {
          key: 'datum',
          label: 'Datum'
        },
        {
          key: 'dauer',
          label: 'Dauer'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  mounted () {
    if (this.uuid) {
      this.mitarbeiter = this.uuid
      this.loadAbwesenheiten()
    }
  },
  watch: {
    mitarbeiter: function () {
      this.loadAbwesenheiten()
    }
  },
  methods: {
    loadAbwesenheiten () {
      if (this.mitarbeiter) {
        Vue.axios.get('/abwesenheit/get/' + this.mitarbeiter)
          .then((response) => {
            this.abwesenheiten = response.data
          })
      }
    },
    newEntry () {
      if (this.mitarbeiter) {
        this.$router.push({ path: '/abwesenheiten/neu/' + this.mitarbeiter })
      } else {
        this.$snotify.error('Bitte wählen Sie zunächst einen Mitarbeiter aus.', {
          position: 'rightTop',
          timeout: 3000
        })
      }
    },
    editEntry (abwesenheitid) {
      this.$router.push({ path: `/abwesenheiten/editieren/${abwesenheitid}` })
    },
    deleteEntry: function (abwesenheitid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Die Abwesenheit / Krankheit wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/abwesenheit/delete/' + abwesenheitid)
            .then((response) => {
              self.$snotify.error('Die Abwesenheit / Krankheit wurde gelöscht.', {
                position: 'rightTop',
                timeout: 4000
              })
              this.loadAbwesenheiten()
            })
        })
        .catch(() => {
          // Do Nothing
        })
    }
  }
}
</script>
